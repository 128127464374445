import React from 'react'
import { Table } from 'semantic-ui-react'
import Context from './context'
import SubCellTable from './subCellTable'

function TableExamplePagination(props) {
    return (
        <Context.Consumer >
            {
                ctx => {
                    return (<Table celled className="myTable">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ textAlign: "center" }}>#</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>المركبة</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>السائق</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>وقت الانطلاق</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>مكان الانطلاق</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>وقت الوصول</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>مكان الوصول</Table.HeaderCell>


                            </Table.Row>
                        </Table.Header>

                        <Table.Body>

                            {

                                // ctx.value.selectedTargetType.id === 0 ? // means unit

                                ctx.value.newData.length > 0 ?
                                    ctx.value.newData.map(function (value, index) {
                                        return (
                                            <Table.Row
                                                style={value.finalTime === "لا يوجد وصول" ? { color: '#ffffff', backgroundColor: "#ff0000" } : null}
                                                key={index}>
                                                <Table.Cell key={index + value.id + 'a'}>{index + 1}</Table.Cell>
                                                <Table.Cell className="cellClass" key={index + value.id + 'b'}>{value.unitName}</Table.Cell>
                                                {/* <Table.Cell
                                                        className="cellClass" key={index + value.id + 'b2'}>{value.status}
                                                    </Table.Cell> */}

                                                <Table.Cell className="cellClass" key={index + value.id + 'c'}>
                                                    {/* <SubCellTable data={value.visits.mar.details.map(e => e.in)}></SubCellTable> */}
                                                    {value.driver}
                                                </Table.Cell>
                                                <Table.Cell className="cellClass" key={index + value.id + 'd'}>
                                                    {/* <SubCellTable data={value.visits.mar.details.map(e => e.out)}></SubCellTable> */}
                                                    {value.initialTime}
                                                </Table.Cell>
                                                <Table.Cell className="cellClass" key={index + value.id + 'e'}>
                                                    {/* <SubCellTable data={value.visits.mar.details.map(e => e.duration)}></SubCellTable> */}
                                                    {value.initialLocation}
                                                </Table.Cell>
                                                <Table.Cell className="cellClass" key={index + value.id + 'f'}>
                                                    {/* <SubCellTable data={value.visits.mar.details.map(e => e.km)}></SubCellTable> */}
                                                    {value.finalTime}
                                                </Table.Cell>
                                                <Table.Cell className="cellClass" key={index + value.id + 'g'}>
                                                    {value.finalLocation}
                                                </Table.Cell>
                                                
                                            </Table.Row>
                                        )
                                    })
                                    :
                                    null

                                // : // if selected type id is 1 means for group
                                // ctx.value.mukadasData.length > 0 ?
                                //     constuctView(ctx.value.mukadasData)
                                //     :
                                //     null

                            }
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>

                            </Table.Row>
                        </Table.Footer>
                    </Table>)
                }
            }
        </Context.Consumer>
    )

}

function constuctView(value) {
    let array = []
    // console.log(value)
    for (let i = 0; i < value.length; i++) {
        // console.log(value[i])
        array.push(<Table.Row
        //style={value.visits[i].weightNetWeight === "---" ? { color: '#ffffff', backgroundColor: "#ff0000" } : null}
        >
            <Table.Cell className="cellClass">{value[i].date}</Table.Cell>
            <Table.Cell className="cellClass" >{value[i].KT_w}</Table.Cell>
            <Table.Cell className="cellClass" >{value[i].KT_c}</Table.Cell>
            <Table.Cell className="cellClass" >{value[i].KM_w}</Table.Cell>
            <Table.Cell className="cellClass" >{value[i].KM_c}</Table.Cell>
            <Table.Cell className="cellClass" >{value[i].TotalK_w}</Table.Cell>
            <Table.Cell className="cellClass" >{value[i].TotalK_c}</Table.Cell>

            <Table.Cell className="cellClass" >{value[i].RT_w}</Table.Cell>
            <Table.Cell className="cellClass" >{value[i].RT_c}</Table.Cell>
            <Table.Cell className="cellClass" >{value[i].RM_w}</Table.Cell>
            <Table.Cell className="cellClass" >{value[i].RM_c}</Table.Cell>
            <Table.Cell className="cellClass" >{value[i].TotalR_w}</Table.Cell>
            <Table.Cell className="cellClass" >{value[i].TotalR_c}</Table.Cell>

            <Table.Cell className="cellClass" >{value[i].TotalK_w === 0 || value[i].TotalR_w === 0 ? "---" : (parseFloat(value[i].TotalK_w) - parseFloat(value[i].TotalR_w)).toFixed(2)}</Table.Cell>

        </Table.Row>
        )
    }
    return array
}


export default TableExamplePagination