import React from "react";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import "./App.css";
// import XLSX from 'xlsx';
import Context from "./components/context";
import MyNav from "./components/MyNav";
import wenkLogo from "./assets/img/wenklogo.png"
import { saveAs } from "file-saver";
import { tsConstructorType } from "@babel/types";
// import moment from 'moment';
import moment from 'moment';
// import { extendMoment } from 'moment-range';
import { RefForward } from "@stardust-ui/react-component-ref";
import datetimeCal from "./components/datetimeCal";

// const moment = extendMoment(Moment);

const ExcelJS = require("exceljs");

const fileJSON = require("./assets/files/constData.json");

/* global wialon */
/* global xlsx */

const RES_NAME = fileJSON.resourceName;
const TARGET_TYPES = fileJSON.targetTypes;
const REPORTS = fileJSON.repoerMaping
const host = "https://local.wenkgps.com"

class App extends React.Component {

  constructor(props) {
    // console.log(fileJSON.targetTypes[1])
    super(props);
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date();
    endDate.setHours(23, 59, 59, 59);

    this.state = {
      token:
        "23e4fde3ef63b576eadc3fd91f22489f09F405C87978BF62D52D8244CFDF70B91B2761F6",
      isAuthorized: false,
      username: "",
      res: [],
      logging: true,
      showAlert: false,
      alertVariant: "success",
      error: false,
      alertMSG: "",
      resultJSON: [],
      selectedRes: {},
      selectedReport: {},
      selectedObject: {},
      units: [],
      unitGroups: [],
      visibleUnitGroups: [],
      showGroups: true,
      accounts: [],
      executing: false,
      exporting: false,
      fromDateVal: startDate,
      toDateVal: endDate,
      fromDateValUnix: this.convertSATToUnix(startDate),
      toDateValUnix: this.convertSATToUnix(endDate),
      targetTypes: TARGET_TYPES,
      selectedTargetType: fileJSON.targetTypes[0], //fileJSON.targetTypes[1], // group is default
      reports: REPORTS,
      selectedReportNew: fileJSON.repoerMaping[0],
      newData: { data: [] }, // for unit [{},{},....] , for group [[{},{},....],[],[]]
      tempData: [], // to hold prepared object arrays of final project
      overlayText: "جاري تسجيل الدخول...",
      loginMode: fileJSON.loginMode,
      isTried: false,
      dataTable: [],
      zones: [],
      filteredZones: [],
      zonesGroups: [],
      selectedZone: {},
      zoneGroup: {},
      isReady: false,
      timeLimitBefore: 150,
      timeLimitAfter: 120,
      mukadasData: [],
      group1: "---",
      group2: "---",
      group3: "---",
      group4: "---",
    };
    this.fromDateOnChange = this.fromDateOnChange.bind(this);
    this.toDateOnChange = this.toDateOnChange.bind(this);
    this.propOpenFunc = this.propOpenFunc.bind(this);
    this.execute = this.execute.bind(this);
    this.onTargetTypeChange = this.onTargetTypeChange.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);
    //  this.onUnitChange = this.onUnitChange.bind(this);
    this.onUnitGroupChange = this.onUnitGroupChange.bind(this);
    this.export = this.export.bind(this);
    this.setAuthHash = this.setAuthHash.bind(this);
    //  this.start = this.start.bind(this);
    this.auth = this.auth.bind(this);
    // this.onZoneChange = this.onZoneChange.bind(this);
  }

  render() {
    // console.log(this.state.visibleUnitGroups)
    return (
      <BrowserRouter basename="/">
        <Context.Provider
          value={{
            value: this.state,
            actions: {
              fromDateOnChange: this.fromDateOnChange,
              toDateOnChange: this.toDateOnChange,
              propOpen: this.propOpenFunc,
              execute: this.execute,
              onTargetTypeChange: this.onTargetTypeChange,
              onAccountChange: this.onAccountChange,
              //  onUnitChange: this.onUnitChange,
              onUnitGroupChange: this.onUnitGroupChange,
              export: this.export,
              setAuthHash: this.setAuthHash,
              //  start: this.start,
              auth: this.auth,
              onZoneChange: this.onZoneChange
            }
          }}
        >
          <Switch>
            <Route path="/start" exact component={MyNav} />
            <Route path="/" component={MyNav} />
          </Switch>
        </Context.Provider>
      </BrowserRouter>
    );
  }
  // async start(e) {
  //   //console.log('hash updated now... going to /')
  //   //await this.setState({authHash:hash})
  //   window.location.href = "/?authHash=09fe3bad5c5671d8c13b9b21bfc6e787";
  // }

  async componentDidMount() {
    // this.auth()
  }

  setAuthHash(aHashVal) {
    // console.log('h updated')
    this.setState({ authHash: aHashVal });
    // console.log('s c')
  }

  /**
   * will be called from MyNav after checking token or hash
   * @param {*} e 
   */
  // async auth1(e) {
  //   // console.log('passed hash')
  //   // console.log(e)

  //   this.auth(e);
  // }

  async auth(hashOrToken) {
    //await this.setState({ token: JSON.parse(localStorage.getItem('wenk_helper_token')), logging: true })
    wialon.core.Session.getInstance().initSession(host);
    // Try to login when component mount
    if (this.state.loginMode === "token") {
      // console.log('mode : T')
      this.loginWithToken(hashOrToken);
    }
    if (this.state.loginMode === "authHash") {
      // console.log('mode : H')
      this.loginWithHash(hashOrToken);
    }
  }

  loginWithToken() {
    let that = this;
    wialon.core.Session.getInstance().initSession(host);
    wialon.core.Session.getInstance().loginToken(
      this.state.token,
      "", // operate as
      async code => {
        // login callback
        // if error code - print error message
        this.setState({ isTried: true });
        if (code) {
          that.setState({
            showAlert: true,
            alertVariant: "error",
            logging: false,
            isAuthorized: false,
            alertMSG: true,
            error: true,
            alertMSG:
              code === 4
                ? "دخول غير مخول"
                : wialon.core.Errors.getErrorText(code)
          });
          return;
        }
        // console.log('authorized')
        await that.setState({
          alertVariant: "success",
          overlayText: "جاري تحميل البيانات....",
          logging: false,
          executing: false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG:
            wialon.core.Session.getInstance()
              .getCurrUser()
              .getName() + " : تم تسجيل الدخول بنجاح!",
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried: true
        });

        await that.init();
      }
    );
  }

  loginWithHash(hash) {
    wialon.core.Session.getInstance().initSession(host);

    let that = this;
    wialon.core.Session.getInstance().loginAuthHash(
      hash,
      "", // try to login
      async code => {
        // this.setState({ isTried: true });

        // login callback
        // if error code - print error message
        if (code) {
          that.setState({
            isTried: true,
            showAlert: true,
            alertVariant: "error",
            logging: false,
            isAuthorized: false,
            alertMSG: true,
            error: true,
            alertMSG: wialon.core.Errors.getErrorText(code)
          });
          return;
        }
        that.setState({
          isTried: true,
          alertVariant: "success",
          overlayText: "جاري تحميل البيانات....",
          logging: false,
          executing: false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG:
            wialon.core.Session.getInstance()
              .getCurrUser()
              .getName() + " : تم تسجيل الدخول بنجاح!",
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried: true
        });

        await that.init();
      }
    );
  }

  init() {
    // console.log('start init')
    let that = this;
    var sess = wialon.core.Session.getInstance();

    sess.loadLibrary("resourceReports");
    //  sess.loadLibrary("resourceZones");
    //  sess.loadLibrary("resourceZoneGroups");

    // flags to specify what kind of data should be returned
    let res_flags =
      wialon.item.Item.dataFlag.base |
      wialon.item.Resource.dataFlag.reports// |
    //   wialon.item.Resource.dataFlag.zoneGroups |
    //   wialon.item.Resource.dataFlag.zones; // 64 bit OR
    let unit_flags = 1;
    let unitGroup_flags = 1;

    sess.updateDataFlags(
      // load items to current session
      [
        { type: "type", data: "avl_resource", flags: res_flags, mode: 0 },
        { type: "type", data: "avl_unit_group", flags: unit_flags, mode: 0 },
        { type: "type", data: "avl_unit", flags: unitGroup_flags, mode: 0 }
      ], // Items specification

      function (code) {
        // updateDataFlags callback
        if (code) {
          // console.log("Error: " + wialon.core.Errors.getErrorText(code));
          return; // exit if error code
        }

        const resourcesArr = sess.getItems("avl_resource"); // get loaded 'avl_resource's items
        // console.log("Res")
        // console.log(resourcesArr)

        /**
         * select report at certain resource (set state with selected resource and report object)
         */
        that.findResource(resourcesArr);

        const unitGroups = sess.getItems("avl_unit_group");
        // const units = sess.getItems("avl_unit");
        // console.log(units)
        // console.log(unitGroups)
        // if (unitGroups.length === 0 && units.length === 0) {
        if (unitGroups.length === 0) {
          that.setState({
            res: resourcesArr,
            showAlert: true,
            error: true,
            alertMSG: "لا توجد مجموعات !",
            alertVariant: "error"
          });
          return;
        }

        let account = fileJSON.repoerMaping[0]
        let groups = []
        // groups = unitGroups.filter(function (obj) {
        //   return obj.$$user_name.includes(account.groups_mask)
        // })
        groups = unitGroups
        // let kabesaThatyIdx = groups.findIndex(function (e) { return (e.$$user_name.includes("ذاتي") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("كابس")) })
        // let kabesaMoajerIdx = groups.findIndex(function (e) { return (e.$$user_name.includes("مؤجر") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("كابس")) })
        // let rafeaThatyIdx = groups.findIndex(function (e) { return (e.$$user_name.includes("ذاتي") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("رافع")) })
        // let rafeaMoajerIdx = groups.findIndex(function (e) { return (e.$$user_name.includes("مؤجر") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("رافع")) })

        // that.onUnitGroupChange( groups[0])

        that.setState({
          res: resourcesArr,
          //     units: units,
          unitGroups: unitGroups,
          visibleUnitGroups: groups,
          selectedObject: groups[0],
          overlayText: "",
          // group1: kabesaThatyIdx >= 0 ? groups[kabesaThatyIdx].$$user_name : "---",
          // group2: kabesaMoajerIdx >= 0 ? groups[kabesaMoajerIdx].$$user_name : "---",
          // group3: rafeaThatyIdx >= 0 ? groups[rafeaThatyIdx].$$user_name : "---",
          // group4: rafeaMoajerIdx >= 0 ? groups[rafeaMoajerIdx].$$user_name : "---"

        });
      }
    );
  }

  /**
   * search for report name from the constData json file then get data from it (zones and groups of zones)
   * @param {*} res 
   * @returns 
   */
  findResource(res) {
    if (RES_NAME === fileJSON.resourceName) {
      for (let i = 0; i < res.length; i++) {
        let that = this;
        if (res[i].$$user_reports) {
          for (let [key, value] of Object.entries(res[i].$$user_reports)) {
            // if (value.n.includes(that.state.selectedTargetType.reportName)) {
            // console.log(that.state.selectedReportNew.name)
            if (value.n.includes(that.state.selectedReportNew.name)) {
              // console.log("Found a report at resource index " + i + " named " + res[i].$$user_name)
              // console.log(value)
              // default selected target type should be group
              // console.log("selectedReport")
              // console.log(value)
              that.setState({
                selectedRes: res[i],
                selectedReport: value
              });
              break;
            }
          }
        }
      }
    }
    // console.log("this.state.selectedReportNew")
    // console.log(this.state.selectedReportNew)
    // console.log("this.state.selectedTargetType")
    // console.log(this.state.selectedTargetType)
    // console.log("this.state.selectedRes")
    // console.log(this.state.selectedRes)

  }



  execute(e) {
    console.log("Executing the report")
    if (
      Object.entries(this.state.selectedRes).length === 0 &&
      this.state.selectedRes.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار المصدر",
        alertVariant: "error"
      });
      return;
    }
    if (
      Object.entries(this.state.selectedReport).length === 0 &&
      this.state.selectedReport.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار التقرير",
        alertVariant: "error"
      });
      return;
    }
    //console.log(Object.entries(this.state.selectedObject))
    if (
      this.state.selectedObject === null
      // Object.entries(this.state.selectedObject).length === 0 &&
      // this.state.selectedObject.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار الهدف",
        alertVariant: "error"
      });
      return;
    }

    if (this.state.fromDateValUnix === "" || this.state.toDateValUnix === "") {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: " يرجى اختيار الفترة اولاً",
        alertVariant: "error"
      });
      return;
    }
    // console.log("fafnafna")
    let interval = {
      from: this.state.fromDateValUnix,
      to: this.state.toDateValUnix,
      flags: wialon.item.MReport.intervalFlag.absolute
    };
    let that = this;
    let sess = wialon.core.Session.getInstance();
    let resou = sess.getItem(this.state.selectedRes._id);
    let template = resou.getReport(this.state.selectedReport.id);

    // first i need to set local datetime

    // this.prepareTempData(this.state.selectedObject);
    console.log(this.state.selectedTargetType)
    if (this.state.selectedTargetType.id !== 3) {
      this.setState({
        isReady: false,
        executing: true,
        overlayText:
          "جاري تنفيذ التقرير... يرجى الانتظار... قد تستغرف عملية التنفيذ اكثر من 3 دقائق اذا كانت الفترة المحددة طويلة"
      });
      let localObj = {
        flags: 0,
        formatDate: "%25Y-%25m-%25E %25H:%25M:%25S"
      };
      sess.getRenderer().setLocale(134228528, "en", localObj, function (code) {
        if (code) {
          that.setState({
            showAlert: true,
            error: true,
            alertMSG: wialon.core.Errors.getErrorText(code),//"جدول البيانات فارغ, لا توجد بيانات",
            alertVariant: "error",
            executing: false,
            overlayText: "",
            isReady: false
          });
          return;
        }
        resou.execReport(
          template,
          that.state.selectedObject._id,
          0,
          interval,
          function (code, data) {
            if (code) {
              that.setState({
                showAlert: true,
                error: true,
                alertMSG: wialon.core.Errors.getErrorText(code),
                alertVariant: "error"
              });
              return;
            }
            if (!data.getTables().length) {
              //console.log(data)
              that.setState({
                showAlert: true,
                error: true,
                alertMSG: "جدول البيانات فارغ, لا توجد بيانات",
                alertVariant: "error",
                executing: false,
                overlayText: "",
                isReady: false
              });
              return;
            }
            // console.log(that.state)
            // that.setState({newData:[],tempData:[], overlayText: 'جاري بناء التقرير' })

            that.preBuild(data);
          }
        );
      });
    } else if (this.state.selectedTargetType.id === 3) {
      // console.log( this.state.visibleUnitGroups)
      let kabesaThatyIdx = this.state.visibleUnitGroups.findIndex(function (e) { return (e.$$user_name.includes("ذاتي") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("كابس")) })
      let kabesaMoajerIdx = this.state.visibleUnitGroups.findIndex(function (e) { return (e.$$user_name.includes("مؤجر") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("كابس")) })
      let rafeaThatyIdx = this.state.visibleUnitGroups.findIndex(function (e) { return (e.$$user_name.includes("ذاتي") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("رافع")) })
      let rafeaMoajerIdx = this.state.visibleUnitGroups.findIndex(function (e) { return (e.$$user_name.includes("مؤجر") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("رافع")) })
      if (
        kabesaThatyIdx >= 0 &&
        kabesaMoajerIdx >= 0 &&
        rafeaThatyIdx >= 0 &&
        rafeaMoajerIdx >= 0
      ) {
        this.setState({
          isReady: false,
          executing: true,
          overlayText:
            "جاري تنفيذ التقرير... يرجى الانتظار... قد تستغرف عملية التنفيذ اكثر من 3 دقائق اذا كانت الفترة المحددة طويلة"
        });
        let localObj = {
          flags: 0,
          formatDate: "%25Y-%25m-%25E %25H:%25M:%25S"
        };
        sess.getRenderer().setLocale(134228528, "en", localObj, function (code) {
          // console.log("Rendering")
          if (code) {
            that.setState({
              showAlert: true,
              error: true,
              alertMSG: wialon.core.Errors.getErrorText(code),//"جدول البيانات فارغ, لا توجد بيانات",
              alertVariant: "error",
              executing: false,
              overlayText: "",
              isReady: false
            });
            return;
          }


          resou.execReport(
            template,
            that.state.visibleUnitGroups[kabesaThatyIdx]._id,
            0,
            interval,
            function (code, kabesaThatyData) {
              if (code) {
                that.wialonError(that, code)
                return;
              }
              if (!kabesaThatyData.getTables().length) {
                //console.log(data)
                // that.error(that)
                // return;
              }


              let kabesaThatyDataTables = (!kabesaThatyData.getTables().length) ? 0 : kabesaThatyData.getTables()
              let kabesaThatyDataIndex = kabesaThatyDataTables === 0 ? -1 : kabesaThatyDataTables.findIndex(function (e) { return (e.label.includes("الوزن")); });
              let K_T_table = {};
              if (kabesaThatyDataIndex >= 0) {
                K_T_table = kabesaThatyDataTables[kabesaThatyDataIndex]
              } else {
                K_T_table.rows = 0
              }
              let reqObjKT = {
                type: "range",
                data: {
                  from: 0,
                  to: K_T_table.rows,
                  level: 5,
                  flat: 0,
                  rawValues: 0
                }
              };

              // let that = this;
              kabesaThatyData.selectRows(kabesaThatyDataIndex, reqObjKT, function (code, col1) {
                // console.log("selectRows + error code " + code)
                // console.log(col1)
                if (code && kabesaThatyDataIndex !== -1) {
                  // means the error is real, then stop the detching
                  that.setState({
                    showAlert: true,
                    error: true,
                    alertMSG: wialon.core.Errors.getErrorText(code),
                    alertVariant: "error"
                  });
                  return;
                }

                resou.execReport(
                  template,
                  that.state.visibleUnitGroups[kabesaMoajerIdx]._id,
                  0,
                  interval,
                  function (code, kabesaMoajerData) {
                    if (code) {
                      that.wialonError(that, code)
                      return;
                    }
                    if (!kabesaMoajerData.getTables().length) {
                      //console.log(data)
                      // that.error(that)
                      // return;
                    }

                    let kabesaMoajerDataTables = (!kabesaMoajerData.getTables().length) ? 0 : kabesaMoajerData.getTables()
                    let kabesaMoajerDataIndex = kabesaMoajerDataTables === 0 ? -1 : kabesaMoajerDataTables.findIndex(function (e) { return (e.label.includes("الوزن")); });
                    let K_M_table = {};
                    if (kabesaMoajerDataIndex >= 0) {
                      K_M_table = kabesaMoajerDataTables[kabesaMoajerDataIndex]
                    } else {
                      K_M_table.rows = 0
                    }
                    let reqObjKM = {
                      type: "range",
                      data: {
                        from: 0,
                        to: K_M_table.rows,
                        level: 5,
                        flat: 0,
                        rawValues: 0
                      }
                    };
                    kabesaMoajerData.selectRows(kabesaMoajerDataIndex, reqObjKM, function (code, col2) {
                      // console.log("selectRows + " + code)
                      // console.log(col2)
                      if (code && kabesaMoajerDataIndex !== -1) {
                        // means the error is real, then stop the detching
                        that.setState({
                          showAlert: true,
                          error: true,
                          alertMSG: wialon.core.Errors.getErrorText(code),
                          alertVariant: "error"
                        });
                        return;
                      }



                      resou.execReport(
                        template,
                        that.state.visibleUnitGroups[rafeaThatyIdx]._id,
                        0,
                        interval,
                        function (code, rafeaThatyData) {
                          if (code) {
                            that.wialonError(that, code)
                            return;
                          }
                          if (!rafeaThatyData.getTables().length) {
                            //console.log(data)
                            // that.error(that)
                            // return;
                          }


                          let R_T_table = {};
                          let rafeaThatyDataTables = (!rafeaThatyData.getTables().length) ? 0 : rafeaThatyData.getTables()
                          let rafeaThatyDataIndex = rafeaThatyDataTables === 0 ? -1 : rafeaThatyDataTables.findIndex(function (e) { return (e.label.includes("الوزن")); });
                          if (rafeaThatyDataIndex >= 0) {
                            R_T_table = rafeaThatyDataTables[rafeaThatyDataIndex]
                          } else {
                            R_T_table.rows = 0
                          }
                          let reqObjRT = {
                            type: "range",
                            data: {
                              from: 0,
                              to: R_T_table.rows,
                              level: 5,
                              flat: 0,
                              rawValues: 0
                            }
                          };

                          rafeaThatyData.selectRows(rafeaThatyDataIndex, reqObjRT, function (code, col3) {
                            // console.log("selectRows + " + code)
                            // console.log(col3)
                            if (code && rafeaThatyDataIndex !== -1) {
                              // means the error is real, then stop the detching
                              that.setState({
                                showAlert: true,
                                error: true,
                                alertMSG: wialon.core.Errors.getErrorText(code),
                                alertVariant: "error"
                              });
                              return;
                            }


                            resou.execReport(
                              template,
                              that.state.visibleUnitGroups[rafeaMoajerIdx]._id,
                              0,
                              interval,
                              function (code, rafeaMoajerData) {
                                if (code) {
                                  that.wialonError(that, code)
                                  return;
                                }
                                if (!rafeaMoajerData.getTables().length) {
                                  //console.log(data)
                                  // that.error(that)
                                  // return;
                                }

                                let R_M_table = {};
                                let rafeaMoajerDataTables = (!rafeaMoajerData.getTables().length) ? 0 : rafeaMoajerData.getTables()
                                let rafeaMoajerDataIndex = rafeaMoajerDataTables === 0 ? -1 : rafeaMoajerDataTables.findIndex(function (e) { return (e.label.includes("الوزن")); });
                                if (rafeaMoajerDataIndex >= 0) {
                                  R_M_table = rafeaMoajerDataTables[rafeaMoajerDataIndex]
                                } else {
                                  R_M_table.rows = 0
                                }
                                let reqObjRM = {
                                  type: "range",
                                  data: {
                                    from: 0,
                                    to: R_M_table.rows,
                                    level: 5,
                                    flat: 0,
                                    rawValues: 0
                                  }
                                };
                                rafeaMoajerData.selectRows(rafeaMoajerDataIndex, reqObjRM, function (code, col4) {
                                  // console.log("selectRows + " + code)
                                  // console.log(col4)
                                  if (code && rafeaMoajerDataIndex !== -1) {
                                    // means the error is real, then stop the detching
                                    that.setState({
                                      showAlert: true,
                                      error: true,
                                      alertMSG: wialon.core.Errors.getErrorText(code),
                                      alertVariant: "error"
                                    });
                                    return;
                                  }

                                  /**
                                   * export from here
                                   */
                                  // console.log(col1)
                                  // console.log(col2)
                                  // console.log(col3)
                                  // console.log(col4)
                                  let localKT = []
                                  let localKM = []
                                  let localRT = []
                                  let localRM = []

                                  if (!("error" in col1)) {
                                    col1.forEach(record => {
                                      let weight = 0
                                      let rides = 0
                                      let obj = {
                                        date: record.c[1],
                                        ridesCnt: rides,
                                        weight: weight,
                                        subData: []
                                      }
                                      record.r.forEach(subRecord => {
                                        let txt = subRecord.c[4];
                                        let txtArr = txt.split(',');
                                        let row = {
                                          fullWeight: txtArr[4].split(':')[1],
                                          fullDate: txtArr[3].split(' : ')[1].split('.')[0],
                                          emptyWeight: txtArr[2].split(':')[1],
                                          emptyDate: txtArr[1].split(' : ')[1].split('.')[0],
                                          netWeight: txtArr[0].split(':')[2],
                                        };
                                        let netWeight = parseInt(txtArr[0].split(':')[2])
                                        if (!that.isDuplicated(row, obj.subData)) {
                                          obj.subData.push(row);
                                          weight += netWeight
                                          rides++;
                                        }


                                      })
                                      obj.ridesCnt = rides
                                      obj.weight = weight
                                      localKT.push(obj)
                                      //  console.log(obj)
                                      //console.log(record.c[1] + "|" + rides + "|" + weight)
                                      // console.log(weight)
                                      // console.log(rides)

                                    })
                                  }
                                  if (!("error" in col2)) {
                                    col2.forEach(record => {
                                      let weight = 0
                                      let rides = 0
                                      let obj = {
                                        date: record.c[1],
                                        ridesCnt: rides,
                                        weight: weight,
                                        subData: []
                                      }
                                      record.r.forEach(subRecord => {
                                        let txt = subRecord.c[4];
                                        let txtArr = txt.split(',');
                                        let row = {
                                          fullWeight: txtArr[4].split(':')[1],
                                          fullDate: txtArr[3].split(' : ')[1].split('.')[0],
                                          emptyWeight: txtArr[2].split(':')[1],
                                          emptyDate: txtArr[1].split(' : ')[1].split('.')[0],
                                          netWeight: txtArr[0].split(':')[2],
                                        };
                                        let netWeight = parseInt(txtArr[0].split(':')[2])
                                        if (!that.isDuplicated(row, obj.subData)) {
                                          obj.subData.push(row);
                                          weight += netWeight
                                          rides++;
                                        }


                                      })
                                      obj.ridesCnt = rides
                                      obj.weight = weight
                                      localKM.push(obj)
                                    })
                                  }
                                  if (!("error" in col3)) {
                                    col3.forEach(record => {
                                      let weight = 0
                                      let rides = 0
                                      let obj = {
                                        date: record.c[1],
                                        ridesCnt: rides,
                                        weight: weight,
                                        subData: []
                                      }
                                      record.r.forEach(subRecord => {
                                        let txt = subRecord.c[4];
                                        let txtArr = txt.split(',');
                                        let row = {
                                          fullWeight: txtArr[4].split(':')[1],
                                          fullDate: txtArr[3].split(' : ')[1].split('.')[0],
                                          emptyWeight: txtArr[2].split(':')[1],
                                          emptyDate: txtArr[1].split(' : ')[1].split('.')[0],
                                          netWeight: txtArr[0].split(':')[2],
                                        };
                                        let netWeight = parseInt(txtArr[0].split(':')[2])
                                        if (!that.isDuplicated(row, obj.subData)) {
                                          obj.subData.push(row);
                                          weight += netWeight
                                          rides++;
                                        }


                                      })
                                      obj.ridesCnt = rides
                                      obj.weight = weight
                                      localRT.push(obj)
                                    })
                                  }
                                  if (!("error" in col4)) {
                                    col4.forEach(record => {
                                      let weight = 0
                                      let rides = 0
                                      let obj = {
                                        date: record.c[1],
                                        ridesCnt: rides,
                                        weight: weight,
                                        subData: []
                                      }
                                      record.r.forEach(subRecord => {
                                        let txt = subRecord.c[4];
                                        let txtArr = txt.split(',');
                                        let row = {
                                          fullWeight: txtArr[4].split(':')[1],
                                          fullDate: txtArr[3].split(' : ')[1].split('.')[0],
                                          emptyWeight: txtArr[2].split(':')[1],
                                          emptyDate: txtArr[1].split(' : ')[1].split('.')[0],
                                          netWeight: txtArr[0].split(':')[2],
                                        };
                                        let netWeight = parseInt(txtArr[0].split(':')[2])
                                        if (!that.isDuplicated(row, obj.subData)) {
                                          obj.subData.push(row);
                                          weight += netWeight
                                          rides++;
                                        }


                                      })
                                      obj.ridesCnt = rides
                                      obj.weight = weight
                                      localRM.push(obj)
                                    })
                                  }
                                  // console.log(localKT)
                                  // console.log(localKM)
                                  // console.log(localRT)
                                  // console.log(localRM)





                                  let from = moment(that.state.fromDateVal, 'YYYY-MM-DD')
                                  let to = moment(that.state.toDateVal, 'YYYY-MM-DD')
                                  const current = from.clone();
                                  const result = [];
                                  while (current.isBefore(to)) {
                                    result.push(current.format("YYYY-MM-DD"));
                                    current.add(1, "day");
                                  }
                                  // console.log(result)
                                  // console.log(dt)
                                  let dataExport = []
                                  let KT = localKT
                                  let KM = localKM
                                  let RT = localRT
                                  let RM = localRM

                                  result.forEach(date => {
                                    let full_day = {
                                      date: date,
                                      KT_w: 0,
                                      KT_c: 0,

                                      KM_w: 0,
                                      KM_c: 0,

                                      TotalK_w: 0,
                                      TotalK_c: 0,

                                      RT_w: 0,
                                      RT_c: 0,

                                      RM_w: 0,
                                      RM_c: 0,

                                      TotalR_w: 0,
                                      TotalR_c: 0,
                                    }

                                    let kt_day = KT.filter(x => x.date === date)
                                    let km_day = KM.filter(x => x.date === date)
                                    let rt_day = RT.filter(x => x.date === date)
                                    let rm_day = RM.filter(x => x.date === date)

                                    if (kt_day.length > 0) {
                                      full_day.KT_w = kt_day[0].weight / 1000
                                      full_day.KT_c = kt_day[0].ridesCnt
                                    }
                                    if (km_day.length > 0) {
                                      full_day.KM_w = km_day[0].weight / 1000
                                      full_day.KM_c = km_day[0].ridesCnt
                                    }
                                    if (rt_day.length > 0) {
                                      full_day.RT_w = rt_day[0].weight / 1000
                                      full_day.RT_c = rt_day[0].ridesCnt
                                    }
                                    if (rm_day.length > 0) {
                                      full_day.RM_w = rm_day[0].weight / 1000
                                      full_day.RM_c = rm_day[0].ridesCnt
                                    }
                                    full_day.TotalK_w = (parseFloat(full_day.KT_w) + parseFloat(full_day.KM_w)).toFixed(2)
                                    full_day.TotalK_c = (parseInt(full_day.KT_c) + parseInt(full_day.KM_c))
                                    full_day.TotalR_w = (parseFloat(full_day.RT_w) + parseFloat(full_day.RM_w)).toFixed(2)
                                    full_day.TotalR_c = (parseInt(full_day.RT_c) + parseInt(full_day.RM_c))



                                    dataExport.push(full_day)
                                  })


                                  that.setState({
                                    executing: false,
                                    overlayText: "",
                                    isReady: true,
                                    mukadasData: dataExport
                                    // mukadasData: {
                                    //   KT: localKT,
                                    //   KM: localKM,
                                    //   RT: localRT,
                                    //   RM: localRM
                                    // }
                                  })

                                })

                                //  that.preBuildMukadas(kabesaThatyData, kabesaMoajerData, rafeaThatyData, rafeaMoajerData);

                              })
                          })
                        })
                    })
                  })

              })


            }
          );
        });
      }
    } else {
      that.setState({
        showAlert: true,
        error: true,
        alertMSG: "لا يحتوي الحساب على المجاميع المطلوبة من قبل مكتب الوكيل البلدي",
        alertVariant: "error",
        executing: false,
        overlayText: "",
        isReady: false
      });
    }
  }

  wialonError(that, code) {
    that.setState({
      showAlert: true,
      error: true,
      alertMSG: wialon.core.Errors.getErrorText(code),
      alertVariant: "error"
    });
  }

  error(that) {
    that.setState({
      showAlert: true,
      error: true,
      alertMSG: "جدول البيانات فارغ, لا توجد بيانات",
      alertVariant: "error",
      executing: false,
      overlayText: "",
      isReady: false
    });
  }



  preBuild(dataTable) {
    console.log("PreBuilding")
    let tables = dataTable.getTables();
    let tripsTableIndex = tables.findIndex(function (e) {
      return (
        e.label.includes("Trips")
        // && e.label.includes(that.state.selectedZone.n)
      );
    });
    let tripsTable = {};
    if (tripsTableIndex >= 0) {
      tripsTable = tables[tripsTableIndex];
    } else {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "لا يوجد جدول خاص بالحضور",
        alertVariant: "error",
        executing: false,
        overlayText: "",
        isReady: false
      });
      return;


    }

    let obj = {
      trip: {
        table: tripsTable,
        index: tripsTableIndex
      },

    };

    this.build(dataTable, obj);
    // this.build(dataTable);
  }

  build(data, obj) {
    console.log("Building")
    let weightsArr = [];
    let that = this;
    let reqObj = {
      type: "range",
      data: {
        from: 0,
        to: obj.trip.table.rows,
        level: 5,
        flat: 0,
        rawValues: 0
      }
    };
    data.selectRows(obj.trip.index, reqObj, function (code, col) {
      // console.log("selectRows + " + code)
      if (code && obj.weight.index !== -1) {
        // means the error is real, then stop the detching
        that.setState({
          showAlert: true,
          error: true,
          alertMSG: wialon.core.Errors.getErrorText(code),
          alertVariant: "error"
        });
        return;
      }
      weightsArr = col;
      // console.log(col)
      //that.fillMukadas(weightsArr)
      that.fill(weightsArr)
      that.setState({
        executing: false,
        overlayText: "",
        isReady: true
      });
    });
  }

  isDuplicated(obj, list) {
    let index = list.findIndex(function (item) {
      return item.fullDate === obj.fullDate && item.emptyDate === obj.emptyDate;
    })

    if (list[index])
      return true

    return false;
  }


  fill(data) {
    let finalArr = []; // get temp array data
    for (let i = 0; i < data.length; i++) {
      let unitName = data[i].c[1]; // get unit Name
      let unitId = data[i].c[0];
      //  let status = "";
      let r = data[i].r;
      let unitObj = {
        id: unitId,
        unitName: unitName,
        initialTime: r[0].c[2].t,
        initialLocation: r[0].c[3].t,
        finalTime: "",
        finalLocation: "",
        driver: r[0].c[6]
      };
      for (let j = 0; j < r.length; j++) {
        // console.log(data[i].r[j].c[5].t.toLowerCase())
        if (data[i].r[j].c[5].t.toLowerCase().includes("site")) {
          unitObj.finalTime = data[i].r[j].c[4].t;
          unitObj.finalLocation = data[i].r[j].c[5].t;
          break; // Exit the loop if a match is found
        }
        if (j == r.length - 1) {
          unitObj.finalTime = "لا يوجد وصول"
          unitObj.finalLocation = "لا يوجد وصول"
        }
      }
      finalArr[i] = unitObj
    }
    console.log(finalArr)
    this.setState({ newData: finalArr });
  }



  fromDateOnChange(e) {
    let from = this.convertSATToUnix(e);
    if (!this.state.fromDateVal === "") {
      if (e) {
        if (this.compareTime(from, this.state.toDateValUnix)) {
          this.setState({ fromDateValUnix: from, fromDateVal: e });
        } else {
          this.setState({
            showAlert: true,
            error: true,
            alertMSG: "يجب ان تكون الفترة المحددة  صحيحة"
          });
        }
      }
    } else {
      this.setState({ fromDateValUnix: from, fromDateVal: e });
    }
  }
  toDateOnChange(e) {
    let to = this.convertSATToUnix(e);
    if (e) {
      if (this.compareTime(this.state.fromDateValUnix, to)) {
        this.setState({ toDateValUnix: to, toDateVal: e });
      } else {
        this.setState({
          showAlert: true,
          error: true,
          alertMSG: "يجب ان تكون الفترة المحددة  صحيحة"
        });
      }
    }
  }
  convertSATToUnix(sat) {
    let longVal = Math.floor(new Date(sat).getTime() / 1000);
    return longVal;
  }
  compareTime(from, to) {
    return from <= to;
  }
  propOpenFunc(e) {
    this.setState({
      showAlert: !this.state.showAlert,
      alertMSG: "",
      error: false
    });
  }


  //  نوع العمل مجموعة ام وحدة ام متكدس
  async onTargetTypeChange(e) {
    // console.log("onTarget ttt")
    if (e) {
      let account = this.state.selectedReportNew
      let groups = []
      if (e.id === 1) {
        groups = this.state.unitGroups.filter(function (obj) {
          return obj.$$user_name.includes(account.groups_mask)
        })

      } else if (e.id === 3) {
        groups = this.state.unitGroups.filter(function (obj) {
          return obj.$$user_name.includes(account.groups_mask) && obj.$$user_name.includes("وكيل")
        })
      }
      this.setState({
        selectedTargetType: e,
        newData: { data: [] },
        selectedObject: groups.length > 0 ? groups[0] : null,
        visibleUnitGroups: groups,
        showGroups: true
      });
      this.findResource(this.state.res);
    }
  }

  /**
   * when change from Baladya to another, their groups should be changed too
   * selectedTargetType 1 groups mizan
   * selectedTargetType 2 units mizan
   * selectedTargetType 3 groups mutakades
   * @param {} e 
   */
  async onAccountChange(e) {
    // console.log("onAccout ttt")
    if (e) {
      let groups = []
      if (this.state.selectedTargetType.id === 1) {
        groups = this.state.unitGroups.filter(function (obj) {
          return obj.$$user_name.includes(e.groups_mask)
        })
      } else if (this.state.selectedTargetType.id === 3) {
        groups = this.state.unitGroups.filter(function (obj) {
          return obj.$$user_name.includes(e.groups_mask) && obj.$$user_name.includes("وكيل")
        })
      }
      let kabesaThatyIdx = groups.findIndex(function (e) { return (e.$$user_name.includes("ذاتي") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("كابس")) })
      let kabesaMoajerIdx = groups.findIndex(function (e) { return (e.$$user_name.includes("مؤجر") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("كابس")) })
      let rafeaThatyIdx = groups.findIndex(function (e) { return (e.$$user_name.includes("ذاتي") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("رافع")) })
      let rafeaMoajerIdx = groups.findIndex(function (e) { return (e.$$user_name.includes("مؤجر") && e.$$user_name.includes("وكيل") && e.$$user_name.includes("رافع")) })

      await this.setState({
        selectedReportNew: e,
        newData: { data: [] },
        visibleUnitGroups: groups,
        selectedObject: groups.length > 0 ? groups[0] : null,
        group1: kabesaThatyIdx >= 0 ? groups[kabesaThatyIdx].$$user_name : "---",
        group2: kabesaMoajerIdx >= 0 ? groups[kabesaMoajerIdx].$$user_name : "---",
        group3: rafeaThatyIdx >= 0 ? groups[rafeaThatyIdx].$$user_name : "---",
        group4: rafeaMoajerIdx >= 0 ? groups[rafeaMoajerIdx].$$user_name : "---"
      });
      this.findResource(this.state.res);
    }
  }

  // onUnitChange(e) {
  //   if (e) {
  //     this.setState({
  //       selectedObject: e,
  //       selectedTargetType: fileJSON.targetTypes[0]
  //     });
  //     // this.prepareTempData(e);
  //   }
  // }

  onUnitGroupChange(e) {
    // console.log("changing unit group")
    if (e) {
      this.setState({
        selectedObject: e,
        selectedTargetType: fileJSON.targetTypes[0]
      });
      // this.prepareTempData(e);
    }
  }





  export(e) {

    let reportName = "تقرير الحضور اليومي - شركة القمة الماسية"//"تقرير  - (" + this.state.selectedObject.$$user_name + ")"
    let fileName = reportName + ".xlsx";
    let str =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let dt = this.state.newData;
    //Creating New Workbook
    let dataStartAtIdx = 7;

    let workbook = new ExcelJS.Workbook();
    //Creating Sheet for that particular WorkBook
    let sheetName = "التفاصيل";
    // let sheetName2 = "الملخص";
    let sheet = workbook.addWorksheet(sheetName, {
      pageSetup: { paperSize: 9, orientation: "landscape" }, // 9 is A4 page
      properties: { showGridLines: true },

    });

    sheet.views = [{ rightToLeft: true, state: 'frozen', ySplit: dataStartAtIdx - 1 }]


    this.createSheetHeader(sheet, dataStartAtIdx, dt, reportName)
    this.createSheet1(sheet, dataStartAtIdx, dt)




    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: str });
      saveAs(blob, fileName);
    });

  }

  borderAll(sheet) {
    // border all
    sheet.eachRow(function (row, rowNumber) {
      // if (rowNumber > minus1) row.style = that.styleCell();
      row.eachCell(function (cell, rowNumberCell) {
        if (rowNumberCell < 15) {
          // console.log(rowNumberCell)
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        }
      });
    });
  }



  createSheet1(sheet, dataStartAtIdx, dt) {
    let minus2 = dataStartAtIdx - 2
    let minus1 = dataStartAtIdx - 1
    //Header must be in below format
    //  minus1 = dataStartAtIdx

    sheet.getRow(minus1).values = [
      "#",
      "اسم المركبة",
      //  "الحضور",

      "وقت الانطلاق", // d
      "موقع الانطلاق", // e
      "وقت الوصول", // f

      "موقع الوصول", // g
      "السائق", // c
    ];
    // sheet.getCell("F" + minus1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: {
    //     argb: 'D3D3D3'
    //   }
    // }

    // Define the range of columns to color (A to H)
    const startColumn = "A";
    const endColumn = "H";

    // Define the fill color
    const fillColor = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "D3D3D3" },
    };

    // Iterate over the columns and set the fill color for each cell
    for (let col = startColumn.charCodeAt(0); col <= endColumn.charCodeAt(0); col++) {
      const column = String.fromCharCode(col);
      const cell = sheet.getCell(column + minus1);
      cell.fill = fillColor;
    }

    sheet.columns = [
      { key: "#", width: 5 },
      { key: "name", width: 23 },
      //    { key: "status", width: 6.44 },

      { key: "initTime", width: 20 }, // d
      { key: "initLocation", width: 22 }, // e
      { key: "destTime", width: 20 }, // f
      { key: "destLocation", width: 15 }, // g
      { key: "driver", width: 16 }, // c
    ];

    sheet.mergeCells("G" + minus1 + ":H" + minus1); // mergce center details
    // sheet.mergeCells("G" + minus2 + ":H" + minus2); // merge eh

    // sheet.getRow(minus2).eachCell({ includeEmpty: false }, function (cell, colNumber) {
    //   cell.font = { size: 12, bold: true };
    //   cell.border = {
    //     top: { style: "thin" },
    //     left: { style: "thin" },
    //     bottom: { style: "thin" },
    //     right: { style: "thin" }
    //   };
    //   cell.alignment = { vertical: "middle", horizontal: "center" };
    //   cell.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: {
    //       argb: 'FFFF00'
    //     }
    //   };
    // });

    sheet.getRow(minus1).eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };

      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // write the data
    let index = dataStartAtIdx;
    let sheetCounter = 0 + index
    // console.log(dt)
    for (let i in dt) {

      sheet.addRow({ name: dt[i].unitName, status: dt[i].status });
      // id: unitId,

      //   for (let j = 0 + index, counter = 0; j < dt[i].visits.length + index; j++) {
      sheet.getCell("A" + sheetCounter).value = sheetCounter - index + 1
      sheet.getCell("B" + sheetCounter).value = dt[i].unitName
      let cell = sheet.getCell("B" + sheetCounter)
      cell.alignment = { wrapText: true };
      sheet.getCell("C" + sheetCounter).value = dt[i].initialTime
      sheet.getCell("D" + sheetCounter).value = dt[i].initialLocation
      cell = sheet.getCell("D" + sheetCounter)
      cell.alignment = { wrapText: true };
      sheet.getCell("E" + sheetCounter).value = dt[i].finalTime
      sheet.getCell("F" + sheetCounter).value = dt[i].finalLocation
      cell = sheet.getCell("F" + sheetCounter)
      cell.alignment = { wrapText: true };
      sheet.mergeCells("G" + sheetCounter + ":H" + sheetCounter);
      sheet.getCell("G" + sheetCounter).value = dt[i].driver
      cell = sheet.getCell("G" + sheetCounter)
      cell.alignment = { wrapText: true };
      // if (dt.data[i].visits[counter].km === "0.00 km") {
      //   sheet.getCell("F" + sheetCounter).fill = {
      //     type: 'pattern',
      //     pattern: 'solid',
      //     fgColor: {
      //       argb: 'FF0000'
      //     }
      //   };
      // }


      // sheet.getCell("G" + sheetCounter).value = dt.data[i].visits[counter].weightNetWeight
      if (dt[i].finalLocation === "لا يوجد وصول") {
        sheet.getCell("E" + sheetCounter).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FF0000'
          }

        };
        sheet.getCell("F" + sheetCounter).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FF0000'
          }

        };
      }
      // sheet.getCell("H" + sheetCounter).value = dt.data[i].visits[counter].weightDate
      // if (dt.data[i].visits[counter].weightDate === "---")
      //   sheet.getCell("H" + sheetCounter).fill = {
      //     type: 'pattern',
      //     pattern: 'solid',
      //     fgColor: {
      //       argb: 'FF0000'
      //     }
      //   };
      // counter++
      sheetCounter++
      //   }

      // sheet
      //   .getRow(index)
      //   .eachCell({ includeEmpty: true }, function (cell, colNumber) {
      //     cell.style = that.styleCell();
      //   });
      // index = index + max;
    }
    this.borderAll(sheet)
  }

  createSheet2(sheet, dataStartAtIdx, dt, reportName) {
    let minus2 = dataStartAtIdx - 2
    let minus1 = dataStartAtIdx - 1
    //Header must be in below format
    sheet.getRow(minus2).values = [
      "الملخص",
      // "الحضور",
      "الملخص", // c
      "الملخص", // d
      "الملخص", // e
      "الملخص", // f

      "الملخص", // g
      "الملخص", // h

    ];

    sheet.getRow(minus1).values = [
      "#",
      "اسم المركبة",
      //  "الحضور",
      "شحنات بوزن", // c
      "شحنات بدون وزن", // d
      "المجموع", // e

      "الوزن كغم (رحلة)", // f
      "الوزن كغم (رحلة)", // g

      "عدد الاوزان بدون رحلة", // h
    ];


    sheet.columns = [
      { key: "#", width: 5 },
      { key: "name", width: 36 },
      //    { key: "status", width: 6.44 },
      { key: "tripWeight", width: 21 }, // c
      { key: "tripnoWehgit", width: 21 }, // d
      { key: "weight", width: 8 }, // e
      //   { key: "trip3", width: 8 }, // e
      { key: "trip4", width: 7 }, // f
      { key: "mizan1", width: 10 }, // g
      { key: "mizan2", width: 21 }, // h
    ];

    sheet.mergeCells("A" + minus2 + ":H" + minus2); // تفاصيل
    // sheet.mergeCells("E" + minus1 + ":F" + minus1); // مجموع
    // sheet.mergeCells("G" + minus1 + ":H" + minus1); // طن
    sheet.mergeCells("F" + minus1 + ":G" + minus1); // وزن بدون رحلة

    sheet.getRow(minus2).eachCell({ includeEmpty: false }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFFF00'
        }
      };
    });

    sheet.getRow(minus1).eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };

      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // write the data
    let index = dataStartAtIdx;
    let sheetCounter = 0 + index
    // console.log(dt.data)
    for (let i in dt.data) {

      sheet.addRow({ name: dt.data[i].unitName, status: dt.data[i].status });
      sheet.getCell("A" + sheetCounter).value = sheetCounter - index + 1
      sheet.getCell("B" + sheetCounter).value = dt.data[i].unitName
      //sheet.mergeCells("E" + sheetCounter + ":F" + sheetCounter); // المجموع
      sheet.getCell("E" + sheetCounter).value = dt.data[i].visits.length // مجموع
      let allTrips = dt.data[i].visits.length
      let allWeights = dt.data[i].weights.length
      let totalTon = 0
      let tripsWithWeigh = 0
      let tripsWithNoWeith = 0
      let weightNoTripsBound = 0
      for (let j = 0 + index, counter = 0; j < allTrips + index; j++) {
        if (dt.data[i].visits[counter].weightNetWeight !== "---") {
          totalTon += dt.data[i].visits[counter].weightNetWeight
          tripsWithWeigh++
        } else {
          tripsWithNoWeith++
        }

        counter++
      }

      if (dt.data[i].weights.length > 0) {
        for (let k = 0; k < allWeights; k++) {
          // console.log(dt.data[i].weights[k])
          if (dt.data[i].weights[k].used == false) {
            weightNoTripsBound++
          }
        }
      }

      // tripsWithNoWeith = allTrips - tripsWithWeigh
      sheet.getCell("C" + sheetCounter).value = tripsWithWeigh
      sheet.getCell("D" + sheetCounter).value = tripsWithNoWeith
      if (tripsWithNoWeith > 0) {
        sheet.getCell("D" + sheetCounter).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FF0000'
          }
        }
      }
      sheet.mergeCells("F" + sheetCounter + ":G" + sheetCounter); // وزن بدون رحلة

      // sheet.mergeCells("G" + sheetCounter + ":H" + sheetCounter); // طن
      sheet.getCell("F" + sheetCounter).value = totalTon
      sheet.getCell("H" + sheetCounter).value = weightNoTripsBound
      if (weightNoTripsBound > 0) {
        sheet.getCell("H" + sheetCounter).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FF0000'
          }
        }
      }
      sheetCounter++
      // console.log(dt.data[i])
      //   // sheet
      //   //   .getRow(index)
      //   //   .eachCell({ includeEmpty: true }, function (cell, colNumber) {
      //   //     cell.style = that.styleCell();
      //   //   });
      //   // index = index + max;
    }
    this.borderAll(sheet)
  }





  createSheet3(sheet, dataStartAtIdx, dt) {
    let minus2 = dataStartAtIdx - 2
    let minus1 = dataStartAtIdx - 1
    //Header must be in below format
    sheet.getRow(minus2).values = [
      "الاوزان الغير مرتبطة برحلة",

      "الاوزان الغير مرتبطة برحلة",

      "الاوزان الغير مرتبطة برحلة",
      "الاوزان الغير مرتبطة برحلة",
      "الاوزان الغير مرتبطة برحلة",
      "الاوزان الغير مرتبطة برحلة",
      "الاوزان الغير مرتبطة برحلة",

    ];

    sheet.getRow(minus1).values = [
      "#",
      "اسم المركبة",
      //  "الحضور",
      "الوزن القائم", // c
      "تاريخ الوزن القائم", // d


      "الوزن الفارغ",// e
      "تاريخ الوزن الفارغ", // f
      "تاريخ الوزن الفارغ", // g
      "الوزن الصافي",// h

    ];


    sheet.columns = [
      { key: "#", width: 5 },
      { key: "name", width: 36 },
      //    { key: "status", width: 6.44 },
      { key: "tripWeight", width: 21 }, // c
      { key: "tripnoWehgit", width: 21 }, // d
      { key: "weight", width: 8 }, // e
      //   { key: "trip3", width: 8 }, // e
      { key: "trip4", width: 7 }, // f
      { key: "mizan1", width: 10 }, // g
      { key: "mizan2", width: 21 }, // h
    ];

    sheet.mergeCells("A" + minus2 + ":H" + minus2); // تفاصيل
    // sheet.mergeCells("E" + minus1 + ":F" + minus1); // الوزن الفارغ

    sheet.mergeCells("F" + minus1 + ":G" + minus1); // تاريخ الوزن الفارغ

    sheet.getRow(minus2).eachCell({ includeEmpty: false }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFFF00'
        }
      };
    });

    sheet.getRow(minus1).eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };

      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // write the data
    let index = dataStartAtIdx;
    let sheetCounter = 0 + index
    // console.log(dt.data)
    for (let i in dt.data) {




      let allWeights = dt.data[i].weights.length

      if (dt.data[i].weights.length > 0) {
        for (let k = 0; k < allWeights; k++) {
          if (!dt.data[i].weights[k].used) {
            sheet.addRow({ name: dt.data[i].unitName, status: dt.data[i].status });
            sheet.getCell("A" + sheetCounter).value = sheetCounter - index + 1
            sheet.getCell("B" + sheetCounter).value = dt.data[i].unitName
            let mizArr = dt.data[i].weights[k].mizanStr.split(",")
            let fullW = mizArr[4].split(":")[1].trim()
            let emptyW = mizArr[2].split(":")[1].trim()
            let weight = fullW - emptyW
            let date = mizArr[3].split(":")[1].trim() + ":" + mizArr[3].split(":")[2].trim() + ":" + mizArr[3].split(":")[3].trim()
            date = date.substr(0, date.lastIndexOf("."))
            let datewmpty = "---"
            if (mizArr[1].includes(".")) {
              datewmpty = mizArr[1].split(":")[1].trim() + ":" + mizArr[1].split(":")[2].trim() + ":" + mizArr[1].split(":")[3].trim()
              datewmpty = datewmpty.substr(0, date.lastIndexOf("."))
            }


            sheet.getCell("C" + sheetCounter).value = parseInt(fullW)
            sheet.getCell("D" + sheetCounter).value = date
            sheet.getCell("E" + sheetCounter).value = emptyW
            sheet.getCell("F" + sheetCounter).value = datewmpty
            sheet.getCell("H" + sheetCounter).value = weight

            sheet.mergeCells("F" + sheetCounter + ":G" + sheetCounter); // تاريخ الوزن القائم	
            sheetCounter++
          }
        }
      }


      // console.log(dt.data[i])
      //   // sheet
      //   //   .getRow(index)
      //   //   .eachCell({ includeEmpty: true }, function (cell, colNumber) {
      //   //     cell.style = that.styleCell();
      //   //   });
      //   // index = index + max;
    }
    this.borderAll(sheet)
  }




  createSheetHeader(sheet, dataStartAtIdx, dt, reportName) {
    let minus5 = dataStartAtIdx - 5 + 1// 8-5 = 3
    let minus4 = dataStartAtIdx - 4 + 1// 8-4 = 4
    let minus3 = dataStartAtIdx - 3 + 1// 8-3 = 5
    // let minus2 = dataStartAtIdx - 2
    // let minus1 = dataStartAtIdx - 1
    sheet.getRow(minus5).values = [
      "نظام وينك لتعقب المركبات",
      "نظام وينك لتعقب المركبات",
      "نظام وينك لتعقب المركبات",
      "شركة ركن الحلول المتكاملة",
      "شركة ركن الحلول المتكاملة",
      "شركة ركن الحلول المتكاملة",
      "شركة ركن الحلول المتكاملة",
      "شركة ركن الحلول المتكاملة"
    ]


    sheet.getRow(minus4).values = [


      "عدد المركبات = " + dt.length,
      "عدد المركبات = " + dt.length,
      "عدد المركبات = " + dt.length,
      "عدد المركبات = " + dt.length,


      "بداية الفترة: " + moment(this.state.fromDateVal).format('YYYY-MM-DD'),
      "بداية الفترة: " + moment(this.state.fromDateVal).format('YYYY-MM-DD'),
      "بداية الفترة: " + moment(this.state.fromDateVal).format('YYYY-MM-DD'),
      "بداية الفترة: " + moment(this.state.fromDateVal).format('YYYY-MM-DD')
    ]


    // let totalTrips = dt.tripsNoWeight + dt.tripsWithWeight
    // let weightTon = dt.totalWeight / 1000
    // let weightNoTrips = dt.weightNoTrips
    sheet.getRow(minus3).values = [
      "وقت تنفيذ التقرير: " + moment().format('YYYY/MM/DD - HH:mm'),
      "وقت تنفيذ التقرير: " + moment().format('YYYY/MM/DD - HH:mm'),
      "وقت تنفيذ التقرير: " + moment().format('YYYY/MM/DD - HH:mm'),
      "وقت تنفيذ التقرير: " + moment().format('YYYY/MM/DD - HH:mm'),
      "نهاية الفترة: " + moment(this.state.toDateVal).format('YYYY-MM-DD'),
      "نهاية الفترة: " + moment(this.state.toDateVal).format('YYYY-MM-DD'),
      "نهاية الفترة: " + moment(this.state.toDateVal).format('YYYY-MM-DD'),
      "نهاية الفترة: " + moment(this.state.toDateVal).format('YYYY-MM-DD')

    ];



    sheet.mergeCells("A1:H2"); // merge name vertically // title of report

    // sheet.mergeCells("A" + minus2 + ":F" + minus2); // mergce center details
    // sheet.mergeCells("G" + minus2 + ":H" + minus2); // merge eh
    sheet.mergeCells("E" + minus3 + ":H" + minus3); // شحنات مع وزن
    sheet.mergeCells("A" + minus3 + ":D" + minus3); // الاوزان الغير مرتبطة برحلة

    sheet.mergeCells("A" + minus4 + ":D" + minus4); // وقت التنفيذ
    sheet.mergeCells("E" + minus4 + ":H" + minus4); // نهاية الفترة
    // sheet.mergeCells("G" + minus4 + ":H" + minus4); //  بداية الفترة

    sheet.mergeCells("A" + minus5 + ":D" + minus5); //  نظام وينك لتعقب المركبات
    sheet.mergeCells("E" + minus5 + ":H" + minus5); //  شركة ركن الحلول المتكاملة


    // يجب اضافة وقت تنفيذ التقرير + المركبات الموزنة بدون ارتباط بشحنات + تفاصيل الوزن


    sheet.getCell("A1").value = reportName;
    sheet.getCell("A1").font = { size: 18, bold: true };
    sheet.getCell("A1").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    };
    sheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center"
    };

    sheet.getRow(minus3).font = { size: 11, bold: true };
    sheet.getRow(minus4).font = { size: 11, bold: true };
    sheet.getRow(minus5).font = { size: 13, bold: true };
    sheet.getCell("A1").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'FFDe00'
      }
    }
    sheet.getCell("A" + minus5).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: '0072BB'
      }
    }
    sheet.getCell("F" + minus5).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: '0072BB'
      }
    }


  }

  styleCell() {
    let style = {
      font: { size: 10, bold: false, name: "Arial" },
      alignment: { vertical: "middle", horizontal: "center" },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      }
    };
    return style;
  }


}

export default App;
export { host }
